<template>
  <section class="pd-top-initial">
    <div class="container px-lg-0">
      <h1>Briefing</h1>
      <div class="card mt-3 mb-3">
        <div class="card-body">
          <div class="row">
            <div class="image-card col-md-2">
              <img
                src="https://i.pinimg.com/474x/03/0a/13/030a132027f578edfec66204633149e4.jpg"
                alt=""
              />
            </div>
            <div class="col">
              <div class="info-card col-md">
                <h1>Projeto re-branding para Enterprise S/A</h1>
              </div>
              <div class="name-card col-md"><h2>Ana Carolina Mendes</h2></div>
              <div class="company-card col-md">
                <a href="#">enterprise.cc</a>
              </div>
            </div>
            <div class="col-md-3 d-flex justify-content-end align-items-end">
              <span class="btn-icons primary">
                <i class="icons ico-chat-light"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header">
            <h2>
              <div
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div class="info">
                  <div class="row">
                    <div class="col-sm-4 d-flex">
                      <div class="info-collapse">Nome do briefing</div>
                      <div class="sub-info-collapse ml-2">ID #4557567567</div>
                    </div>
                    <div class="col-sm d-flex">
                      <div class="info-collapse">Início</div>
                      <div class="sub-info-collapse ml-2">18/09/2019</div>
                    </div>
                    <div class="col-sm d-flex">
                      <div class="info-collapse">Término</div>
                      <div class="sub-info-collapse ml-2">18/09/2019</div>
                    </div>
                    <div class="col-sm d-flex">
                      <div class="info-collapse">Budget</div>
                      <div class="sub-info-collapse ml-2">R$ 9.000,00</div>
                    </div>

                    <div class="col-sm">
                      <h5
                        class="card-title details-collapse collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-controls="collapseOne"
                      >
                        Ver detalhes
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </h2>
          </div>

          <div id="collapseOne" class="collapse">
            <div class="card-body">
              <div class="row">
                <div class="col-sm">
                  <div class="info-collapse">Equipamento</div>
                  <div class="sub-info-collapse">Fornecido</div>
                </div>
                <div class="col-sm">
                  <div class="info-collapse">Software específico</div>
                  <div class="sub-info-collapse">Nenhum</div>
                </div>
                <div class="col-sm">
                  <div class="info-collapse">Término</div>
                  <div class="sub-info-collapse">E-mail. Sede. Aplicativo</div>
                </div>
                <div class="col-sm">
                  <div class="info-collapse">Anexos</div>
                  <div class="sub-info-collapse">arquivo.png</div>
                </div>
              </div>

              <p class="mt-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="c-b-payment">
        <div class="wrapper-timeline">
          <div class="progress-box">
            <p>Pendente</p>
            <h2 style="color: #000000; opacity: 0.7; font-size: 18px">
              Aprovação da proposta
            </h2>
          </div>
          <div class="wrapper-timeline">
            <div class="progress-box">
              <p>08 / 11 / 2019</p>
              <h2 v-show="rejection & accept">Enviar proposta</h2>
              <h2 v-show="!rejection">Proposta rejeitada</h2>
              <h2 v-show="!accept">Proposta enviada</h2>
            </div>
          </div>
          <div class="card-white rounded px-5 d-block calculate">
            <div v-show="rejection & accept">
              <h2 style="font-size: 20px">Detalhe sua proposta</h2>
              <p>
                Dúvidas como cobrar?
                <span
                  ><a href="https://creators.llc/calculadora/" target="_blank"
                    >Veja nossa calculadora de Creators</a
                  ></span
                >
              </p>
              <div>
                <div v-for="count in totalCount" :key="`teste-${count}`">
                  <div class="calculator-creator mt-5">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="chanel"
                            >Quais canais serão publicados os conteúdos?</label
                          >
                          <select id="chanel" class="form-control">
                            <option selected>Youtube</option>
                            <option>Instagram</option>
                            <option>Tiktok</option>
                            <option>Twitter</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="chanel"
                            >Quais canais serão publicados os conteúdos?</label
                          >
                          <select id="chanel" class="form-control">
                            <option selected>Shorts</option>
                            <option>Reels</option>
                            <option>Posts</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label>Posts neste formato que serão produzidos?</label>
                        <input
                          type="text"
                          class="form-control"
                          id="calculo"
                          @keyup="multiplicacao($event)"
                        />
                        <div class="btn-input">
                          <button @click="decrementando()">
                            <p style="color: #000000">-</p>
                          </button>
                          <button @click="incrementando()">
                            <p style="color: #000000">+</p>
                          </button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label>Qual o valor médio por tipo de post?</label>
                        <input
                          type="text"
                          @keypress="onlyNumber"
                          @keyup="multiplicacao($event)"
                          class="form-control"
                          id="money"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p class="mt-4">
                      Valor deste conteúdo:
                      {{
                        result.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </p>
                  </div>

                  <div class="add-social mt-3 d-flex justify-content-between">
                    <div @click="show = !show">
                      <h2>
                        <button class="add-pointer" @click="duplicateEl">
                          <p style="color: #7553e2">+</p>
                        </button>
                        Adicionar rede social e formato
                      </h2>
                    </div>

                    <div>
                      <h2 style="color: black">
                        <button
                          style="background: transparent; border: none"
                          @click="excludeEl"
                        >
                          <p style="color: black">
                            <i class="far fa-trash-alt"></i>
                          </p>
                        </button>
                        Deletar esta precificação
                      </h2>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <p>Total em R$:</p>
                    <p class="final-value mt-2">
                      {{
                        result.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </p>
                  </div>
                  <div class="col-md d-flex flex-wrap justify-content-end">
                    <button
                      class="btn btn-outline-primary w-auto mx-2"
                      data-toggle="modal"
                      data-target="#staticBackdrop"
                      type="button"
                    >
                      Recusar
                    </button>

                    <!-- Modal -->
                    <div
                      class="modal fade"
                      id="staticBackdrop"
                      data-backdrop="static"
                      data-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <p>
                              Selecione o motivo do cancelamento para
                              melhorarmos nosso match e trazer apenas o que mais
                              importa para você
                            </p>
                            <form>
                              <div
                                class="
                                  col-md
                                  mt-5
                                  form-control
                                  d-flex
                                  align-items-center
                                  radio-section
                                "
                              >
                                <input name="radio" type="radio" />
                                <span class="mx-2"
                                  >Não tenho mais disponibilidade para o
                                  período</span
                                >
                              </div>
                              <div
                                class="
                                  col-md
                                  mt-2
                                  form-control
                                  d-flex
                                  align-items-center
                                  radio-section
                                "
                              >
                                <input name="radio" type="radio" />
                                <span class="mx-2"
                                  >Não tenho mais interesse no projeto</span
                                >
                              </div>

                              <div
                                class="
                                  col-md
                                  mt-2
                                  form-control
                                  d-flex
                                  align-items-center
                                  radio-section
                                "
                              >
                                <input name="radio" type="radio" />
                                <span class="mx-2"
                                  >Tive um problema Pessoal</span
                                >
                              </div>

                              <div
                                class="
                                  col-md
                                  mt-2
                                  form-control
                                  d-flex
                                  align-items-center
                                  radio-section
                                "
                              >
                                <input
                                  name="radio"
                                  type="radio"
                                  @click="show = !show"
                                />
                                <span class="mx-1">Outro</span>
                              </div>
                              <div v-if="!show" class="mt-4">
                                <label
                                  for="exampleFormControlInput1"
                                  class="form-label"
                                  >Conte um pouco sobre seu motivo</label
                                >
                                <input
                                  type="email"
                                  class="form-control bg"
                                  placeholder="Digite aqui o motivo do cancelamento"
                                />
                              </div>
                            </form>
                          </div>
                          <div class="modal-body d-flex">
                            <button
                              type="button"
                              class="btn w-2 btn-outline-primary"
                              data-dismiss="modal"
                            >
                              Pular
                            </button>
                            <button
                              @click="rejection = !rejection"
                              type="button"
                              class="mx-1 w-2 btn btn-primary"
                              data-dismiss="modal"
                            >
                              Enviar motivo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      @click="accept = !accept"
                      class="btn btn-primary w-auto mx-2"
                    >
                      Enviar orçamento
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!rejection">
              <div class="d-flex align-items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0039 0C6.99403 0 5.52883 0.00517075 5.24402 0.0288035C4.21591 0.114288 3.57615 0.276204 2.87917 0.62331C2.34204 0.890102 1.91842 1.19936 1.50035 1.63288C0.738952 2.42347 0.277506 3.3961 0.11046 4.55226C0.0292447 5.11354 0.00561946 5.22801 0.000820359 8.09496C-0.00102545 9.05061 0.000820359 10.3083 0.000820359 11.9953C0.000820359 17.0025 0.00635283 18.4666 0.0303483 18.7509C0.11341 19.7516 0.27031 20.3812 0.602555 21.0699C1.23751 22.3882 2.45021 23.3778 3.87886 23.7471C4.37354 23.8745 4.9199 23.9446 5.62131 23.9778C5.91848 23.9908 8.94744 24 11.9783 24C15.0091 24 18.0399 23.9963 18.3297 23.9815C19.1418 23.9433 19.6134 23.88 20.1349 23.7452C21.5728 23.3741 22.7633 22.3992 23.4112 21.0625C23.737 20.3905 23.9022 19.7369 23.9769 18.7884C23.9932 18.5816 24 15.2847 24 11.9922C24 8.69908 23.9926 5.40821 23.9764 5.20142C23.9007 4.23765 23.7355 3.58959 23.3992 2.90461C23.1232 2.34389 22.8168 1.92515 22.372 1.49699C21.5781 0.73853 20.6072 0.276948 19.4503 0.110042C18.8897 0.0289886 18.778 0.00498504 15.9096 0H12.0039Z"
                    fill="#DA5356"
                  />
                  <g clip-path="url(#clip0_7932_49658)">
                    <path
                      d="M12.0002 18.6666C15.6821 18.6666 18.6668 15.6819 18.6668 12C18.6668 8.31808 15.6821 5.33331 12.0002 5.33331C8.31826 5.33331 5.3335 8.31808 5.3335 12C5.3335 15.6819 8.31826 18.6666 12.0002 18.6666Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 10L10 14"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 10L14 14"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7932_49658">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(4 4)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div>
                  <h2 class="mt-2 ml-2" style="font-size: 20px">
                    Proposta rejeitada
                  </h2>
                </div>
              </div>

              <p>
                Se mudar de ideia e quiser enviar uma proposta detalhada no
                futuro basta clicar no botão e fazer sua proposta.
                <span
                  ><a style="font-weight: 700" href="">
                    <br />
                    Mudei de ideia</a
                  ></span
                >
              </p>
            </div>

            <div v-show="!accept">
              <div class="d-flex align-items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0039 0C6.99403 0 5.52883 0.00517075 5.24402 0.0288035C4.21591 0.114288 3.57615 0.276204 2.87917 0.62331C2.34204 0.890102 1.91842 1.19936 1.50035 1.63288C0.738952 2.42347 0.277506 3.3961 0.11046 4.55226C0.0292447 5.11354 0.00561946 5.22801 0.000820359 8.09496C-0.00102545 9.05061 0.000820359 10.3083 0.000820359 11.9953C0.000820359 17.0025 0.00635283 18.4666 0.0303483 18.7509C0.11341 19.7516 0.27031 20.3812 0.602555 21.0699C1.23751 22.3882 2.45021 23.3778 3.87886 23.7471C4.37354 23.8745 4.9199 23.9446 5.62131 23.9778C5.91848 23.9908 8.94744 24 11.9783 24C15.0091 24 18.0399 23.9963 18.3297 23.9815C19.1418 23.9433 19.6134 23.88 20.1349 23.7452C21.5728 23.3741 22.7633 22.3992 23.4112 21.0625C23.737 20.3905 23.9022 19.7369 23.9769 18.7884C23.9932 18.5816 24 15.2847 24 11.9922C24 8.69908 23.9926 5.40821 23.9764 5.20142C23.9007 4.23765 23.7355 3.58959 23.3992 2.90461C23.1232 2.34389 22.8168 1.92515 22.372 1.49699C21.5781 0.73853 20.6072 0.276948 19.4503 0.110042C18.8897 0.0289886 18.778 0.00498504 15.9096 0H12.0039Z"
                    fill="#7553E2"
                  />
                  <g clip-path="url(#clip0_7932_49731)">
                    <path
                      d="M10.1568 18.8496L18.1154 8.77768L5.33325 9.96069L7.20908 13.4175L15.1525 10.3855L8.28098 15.3928L10.1568 18.8496Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7932_49731">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(4 5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div>
                  <h2 class="mt-2 ml-2" style="font-size: 20px">
                    Proposta enviada
                  </h2>
                </div>
              </div>

              <p>
                Se mudar de ideia e quiser atualizar sua proposta basta clicar
                no botão e refazer sua proposta.
                <span
                  ><a style="font-weight: 700" href=""
                    ><br />
                    Mudei de ideia</a
                  ></span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.image-card {
  padding: 15px 30px;
  margin-right: -60px !important;
  img {
    width: 100px;
    height: auto;
    object-fit: cover;
    border-radius: 50%;
  }
}

.radio-section {
  span {
    color: #7f7f7f !important;
  }
}
.info-card {
  h1 {
    font-size: 20px;
  }
}
.name-card {
  h2 {
    font-size: 15px;
    margin-top: -20px;
  }
}
label {
  font-weight: 500 !important;
}
.company-card {
  a {
    font-size: 15px;
    margin-top: -35px;
    text-decoration: underline;
    color: #7f7f7f;
    font-weight: 600;
  }
}
.info-collapse,
.sub-info-collapse,
.details-collapse {
  font-size: 14px;
  flex-basis: auto;
  color: black;
  font-weight: 600;
}
.sub-info-collapse {
  color: #7f7f7f;
}


.wrapper-timeline:nth-child(1) .progress-box:before {
  background-color: #f0f0f0 !important;
  border: 2px solid #7f7f7f;
}
.wrapper-timeline:nth-child(2) .progress-box:before {
  background-color: #7553e2 !important;
  border: none !important;
}
.card-header {
  background-color: white !important;
}
.calculate {
  span {
    font-size: 15px;
    a {
      color: #7553e2;
      font-size: 1rem;
    }
  }
}
.modal {
  .modal-body {
    p {
      color: inherit;
      font-weight: 600;
      text-align: center;
      font-size: 16px;
    }
  }
}

.form-control {
  height: 50px;
  padding: 4px 10px 4px 10px !important;
}
.modal-body {
  .form-control {
    border: 1px solid #d3d4d8 !important;
    background-color: white !important;
  }
}

.add-social {
  h2 {
    color: #7553e2;
    font-size: 15px;
  }
}
.add-social {
  .add-pointer {
    width: 20px;
    height: 21px;
    border: none;
    background: rgba(117, 83, 226, 0.1);
    border-radius: 50%;
    font-weight: 900;
    text-align: center;
    float: left;
    top: 50%;
    position: relative;
    display: flex;
    font-size: 17px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    padding-bottom: 2px;
    margin-right: 5px;
    cursor: pointer;
  }
}
.btn-input {
  position: absolute;
  right: 25px;
  margin-top: -40px;

  button {
    margin-left: 15px;
    width: 30px;
    height: 30px;
    border: none;
    background: white;
    border-radius: 50%;
    font-weight: 900;
    text-align: center;
    float: left;
    top: 50%;
    position: relative;
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
  }
  .close {
    span {
      color: black !important;
    }
  }
}

.btn {
  padding: auto 24px !important;
}

.final-value {
  color: black !important;
  font-size: 36px !important;
  font-weight: 800 !important;
}

@media screen and (max-width: 600px) {
  .card-white,
  .card-header {
    padding: 15px !important;
  }
}
</style>



<script>
export default {
  data() {
    return {
      cliques: "",
      result: "00,00",
      show: true,
      rejection: true,
      accept: true,
      totalCount: 1,
    };
  },
  methods: {
    incrementando() {
      this.cliques = calculo.value++;
    },
    decrementando() {
      this.cliques = calculo.value--;
    },
    multiplicacao() {
      this.result = money.value * calculo.value;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    duplicateEl() {
      this.totalCount++;
    },
    excludeEl() {
      if (this.totalCount > 1) this.totalCount--;
    },
  },

  mounted() {},
};
</script>

